<!--
 * @Author: dongjia
 * @Date: 2021-06-25 16:57:10
 * @LastEditTime: 2021-06-25 18:07:54
 * @LastEditors: aleaner
 * @Description: 商品分类列表管理
 * @FilePath: \saas-admin-vue\src\modules\wx-store\views\product-category.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <el-button class="top-btn" size="small" type="primary" @click="showAddCategory = true">添加分类</el-button>
    <list-filter :value="categoryForm" @update="getCategoryList"></list-filter>
    <div class="table-tab"></div>
    <div class="list-content" v-loading="showLoading">
      <div class="list-btn">
        <el-button size="small" type="danger" @click="handleMultiDel">删除</el-button>
      </div>
      <el-table :data="categoryList" class="thead-light" stripe
                @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <el-table-column v-for="(item,index) in categoryTable" :key="index" :label="item.label"
                         :prop="item.prop" :min-width="item.min_width" :sortable="item.sortable">
          <template slot-scope="scope">
            <PopoverInputEdit v-if="item.prop === 'sort'" v-model="scope.row.sort" input-type="number"
                              :validator="validator" @confirm="upDateSort(scope.row)" />
            <span v-else>{{scope.row[item.prop] | placeholder }}</span>
          </template>
        </el-table-column>
        <el-table-column label="显示" width="80">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_show" :true-label="1" :false-label="0"
                         @change="changeShow(scope.row.id,$event)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170" fixed="right">
          <template slot-scope="scope">
            <el-button style="color: #3576FF" size="small" type="text" @click="handleEditCategory(scope.row)">编辑
            </el-button>
            <el-button style="color: #3576FF" size="small" type="text" @click="handleTransfer(scope.row.id)">转移
            </el-button>
            <el-button style="color: #3576FF" size="small" type="text" @click="handleDelCategory(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <AddCategory :showAddCategory="showAddCategory" :editObj="editObj" @closeAddDialog="closeAddDialog"
      @updateData="updateData"></AddCategory>
    <CategoryTransfer :showTransfer="showTransfer" :transferId="transferId"
      @closeTransfer="showTransfer = false" @transferSuccess="transferSuccess" :updateList="RefreshList">
    </CategoryTransfer>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage"></Pagination>
  </div>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";

import {
  fetchCategoryList,
  removeCategory,
  updateCategoryShow,
  updateCategorySort
} from "@/modules/wx-channels/api/category-list";

import ListFilter from "@/modules/wx-channels/components/category-list/ListFilter";
import AddCategory from "../components/category-list/AddCategory";
import CategoryTransfer from "../components/category-list/CategoryTransfer";
export default {
  name: "wx-channels-category",
  components: {
    ListFilter,
    Pagination,
    AddCategory,
    CategoryTransfer,
    PopoverInputEdit,
  },
  data() {
    return {
      categoryForm: {
        page_size: 15,
        page: 1,
        start_time: -1,
        end_time: -1,
        name: ""
      },
      pageData: {},
      categoryTable: [
        { label: "排序", prop: "sort", min_width: 80 },
        // { label: "ID", prop: "id", min_width: 100 },
        { label: "分类名称", prop: "name", min_width: 100 },
        {label: '视频数量', prop: 'videos_count', min_width: 100},
        {label: '直播数量', prop: 'lives_count', min_width: 100},
      ],
      validator: {
        type: "integer",
        min: 1,
        message: "最小不能低于1的整数",
        trigger: "blur",
      },
      categoryList: [],
      selectArr: [],
      contents_count_arr: [],
      showLoading: false,
      showAddCategory: false,
      editObj: {},
      showTransfer: false,
      transferId: "",
    };
  },
  created() {
    this.getCategoryList(this.categoryForm);
  },
  methods: {
    handleDelCategory(data) {
      let delTitle = "";
      let delBtn = "";
      if (data.videos_count > 0 || data.lives_count > 0) {
        delTitle = "该分类下还有信息内容，建议先进行内容转移，再删除";
        delBtn = "仍要删除";
      } else {
        delTitle = "确认要将该分类删除吗？删除后无法恢复。";
        delBtn = "删除";
      }
      this.$confirm(delTitle, "提示", {
        confirmButtonText: delBtn,
        cancelButtonText: "取消",
        type: "error",
      })
        .then((res) => {
          this.showLoading = true;
          removeCategory(data.id)
            .then((res) => {
              this.$message.success(res.msg);
              this.RefreshList();
            })
            .catch((err) => {
              this.showLoading = false;
            });
        })
        .catch((err) => {});
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
        this.contents_count_arr = [];
      } else {
        this.selectArr = [];
        this.contents_count_arr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
          this.contents_count_arr.push(item.lives_count + item.videos_count);
        });
      }
    },
    // 批量删除
    handleMultiDel() {
      if (this.selectArr.length) {
        let delTitle = "";
        let delBtn = "";
        if (this.contents_count_arr.findIndex((el) => el > 0) !== -1) {
          delTitle = "这些分类下还有信息内容，建议先进行内容转移，再删除";
          delBtn = "仍要删除";
        } else {
          delTitle = "确认要将这些分类删除吗？删除后无法恢复。";
          delBtn = "删除";
        }
        this.$confirm(delTitle, "提示", {
          confirmButtonText: delBtn,
          cancelButtonText: "取消",
          type: "error",
        })
          .then((res) => {
            this.showLoading = true;
            removeCategory(this.selectArr)
              .then((res) => {
                this.$message.success(res.msg);
                this.RefreshList();
              })
              .catch((err) => {
                this.showLoading = false;
              });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    transferSuccess() {
      this.showTransfer = false;
      this.transferId = 0;
    },
    handleTransfer(id) {
      this.showTransfer = true;
      this.transferId = id;
    },
    closeAddDialog() {
      this.showAddCategory = false;
      this.editObj = {};
    },
    handleEditCategory(data) {
      this.editObj = JSON.parse(JSON.stringify(data));
      this.showAddCategory = true;
    },
    updateData() {
      this.showAddCategory = false;
      this.RefreshList();
    },
    updateList(data) {
      this.categoryForm = { ...this.categoryForm, ...data };
      this.getCategoryList(this.categoryForm);
    },
    changeShow(id, e) {
      this.showLoading = true;
      updateCategoryShow(id, e)
        .then((res) => {
          this.$message.success(res.msg);
          this.RefreshList();
        })
        .catch((err) => {
          this.showLoading = false;
        });
    },
    upDateSort(row) {
      this.showLoading = true;
      updateCategorySort(row.id, row.sort)
        .then((res) => {
          this.$message.success(res.msg);
          this.RefreshList();
        })
        .catch((err) => {
          this.showLoading = false;
        });
    },
    sortChange(e) {
      this.categoryForm.order_by = e.prop;
      this.RefreshList();
    },
    changPage(data) {
      this.categoryForm.page_size = data.page_size;
      let requestData = { ...this.categoryForm, ...data };
      this.getCategoryList(requestData);
    },
    // 刷新列表
    RefreshList() {
      const data = {
        page: this.pageData.crrent_page,
        ...this.categoryForm,
      };
      this.getCategoryList(data);
    },
    getCategoryList(requestData) {
      this.showLoading = true;
      this.pageData = {};
      fetchCategoryList(requestData)
        .then((res) => {
          this.categoryList = res.data.data;
          this.pageData = res.data;
          this.showLoading = false;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss">
</style>
