<template>
  <layout-filter :onFilter="doFilter" :onReset="resetForm" :toggle-collapse-enable="false">
    <el-form :model="value" :rules="rules" class="filter-from" ref="userListFilterForm" label-width="70px"
      inline size="small">
      <div class="filter-item">
        <el-form-item label="分类名称：" prop="name">
          <el-input placeholder="请输入" v-model="value.name" @keyup.enter.native="doFilter" clearable></el-input>
<!--          <el-select v-model="value.id">-->
<!--            <el-option v-for="(c) in categories" :key="c.id" :label="c.name" :value="c.id"></el-option>-->
<!--          </el-select>-->
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="创建时间：">
          <DatePeriodPicker start-prop="start_time" :start-time.sync="value.start_time"
            end-prop="end_time" :end-time.sync="value.end_time" />
        </el-form-item>
      </div>
    </el-form>
  </layout-filter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

export default {
  name: "ListFilter",
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {},
      categories: []
    };
  },
  created() {

  },
  methods: {
    doFilter: function () {
      this.$emit("update", this.value);
    },
    // 重置表单
    resetForm() {
      this.$refs["userListFilterForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
